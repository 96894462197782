<template>
  <simple-layout>
    <v-container style="max-width: 1024px;">
      <v-row justify="center">
        <v-col>
          <v-btn
            @click="$router.push('/reschedule/clinics/' + getAppointment.id)"
            color="primary"
            class="ml-3 mb-4 white--text v-btn--primary"
          >
            <v-icon class="mr-4">mdi-arrow-left-thin-circle-outline</v-icon>
            <span>Back to clinics selection</span>
          </v-btn>
          <patient-appointment-booking-slots 
            @selected="onSlots1Continue"
            @continue="onSlots1Continue" />
        </v-col>
      </v-row>
    </v-container>
  </simple-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import SimpleLayout from '@router/layouts/simple.vue'
import PatientAppointmentBookingSlots from '@components/appointment/booking/slots/Slots.vue'

export default {
  name: 'AppointmentRescheduleSlots1',
  components: {
    SimpleLayout,
    PatientAppointmentBookingSlots
  },
  data () {
    return {
      loading: false,
      appointment: null
    }
  },
  mounted () {
    if (!this.getClinic) {
      this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters('booking', [
      'getAppointment', 'getClinic'
    ]),
  },
  methods: {
    onSlots1Continue() {
      this.$router.push('/reschedule/slots2')
    }
  }
}
</script>
