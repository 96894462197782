<template>
  <div class="pa-2 text-center home-text-2 slots__not-available">
    <p>
      <img src="/img/icons/not-available.svg" alt="No slots available" />
    </p>
    <div>
      <h3 v-if="iterationsOnSearchNextSlotsLimitReached" class="text-center text-subtitle-1">
        We could not find availability within next {{ iterationsOnSearchNextSlotsLimitDays }} days. Please continue to search alternative times and dates.
      </h3>
      <h3 v-else class="text-center text-subtitle-1">
        There is no availability in this time slot please continue to search alternative times and dates.
      </h3>
    </div>
  </div>
</template>

<style scoped>
.slots__not-available {
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}
</style>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'SlotsNotAvailable',
  props: ['iterationsOnSearchNextSlotsLimitReached', 'iterationsOnSearchNextSlotsLimitDays']
});
</script>