<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-container class="py-0">
        <v-card :loading="clinicsLoading" class="mx-auto">
          <template slot="progress">
            <v-progress-linear
              color="primary"
              indeterminate
            ></v-progress-linear>
            <p v-if="!visible && !ignorePreferredClinicSearch" class="my-16 px-4 text-center font-weight-bold">{{ $_t('Please wait while we search for available slots in your preferred clinic. This may take a moment or two, please do not refresh the page.') }}</p>
            <p v-if="!visible && ignorePreferredClinicSearch" class="my-16 px-4 text-center font-weight-bold">{{ $_t('Unfortunately, we have no available slots in your preferred clinic at this time. Please wait while we search for available slots in all our locations. This may take a minute or two to complete, please do not refresh the page.') }}</p>
            <p v-if="visible" class="my-16 px-4 text-center font-weight-bold">{{ $_t('Please wait while we search for available slots in all our locations. This may take a minute or two to complete, please do not refresh the page.') }}</p>
          </template>
        </v-card>
        <div v-if="!clinicsLoading && bookingPossible">
          <v-row>
            <v-col cols="12">
              <h4 class="text-center mt-4">
                <span v-if="preferredClinicNotFound">{{ $_t('Unfortunately, there is currently no availability in your chosen clinic, please see other options below.') }}<br></span>
                <span>{{ $_t('If you would prefer to speak to an agent please click on "Call me back to help book my appointment" below.') }}</span>
              </h4>
            </v-col>
            <v-col cols="12">
              <patient-appointment-booking-call-me-back :appointment-id="getAppointment.id"></patient-appointment-booking-call-me-back>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title class="px-6">
                  <span class="primary--text">Where are you today?</span>
                </v-card-title>
                <v-card-text>
                  <div class="pa-2">
                    <v-row v-if="googleMapsApiKeyLoaded">
                      <v-col cols="12" class="py-0">
                        <label
                          class="d-inline-flex mb-2 home-input-label font-weight-bold"
                          >Postcode or town</label
                        >
                      </v-col>
                      <v-col cols="12" lg="5" class="pt-0">
                        <div style="position: relative;">
                          <vue-google-autocomplete
                            id="filter-google-autocomplete"
                            ref="fga"
                            classname="google-autocomplete"
                            placeholder=""
                            v-on:placechanged="onGoogleAutocompletePlaceChanged"
                            v-on:inputChange="onGoogleAutocompleteInputChanged"
                            country="uk"
                            types="(regions)"
                          >
                          </vue-google-autocomplete>
                          <v-btn
                            v-if="showIconCurrentLocation"
                            @click="onIconCurrentLocationClicked"
                            color="primary"
                            class="icon__current-location"
                            fab
                            x-small
                            icon
                            outlined
                          >
                            <v-icon>mdi-map-marker-radius</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="showIconClearLocation"
                            @click="onIconClearLocationClicked"
                            color="primary"
                            class="icon__clear-location"
                            fab
                            x-small
                            icon
                            outlined
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="3" class="pt-0">
                        <v-select
                          v-model="distance"
                          :items="distances"
                          item-text="label"
                          item-value="value"
                          class="align-self-end"
                          dense
                          solo
                          hide-details
                        >
                          <template #item="{item}">
                            <span class="notranslate">{{ item.label }}</span>
                          </template></v-select
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" lg="8">
                        <label
                          for="filter-clinic-names"
                          class="d-inline-flex mb-2 home-input-label font-weight-bold"
                          >Find clinic by name</label
                        >
                        <v-autocomplete
                          v-model="filteredClinicIdsByName"
                          :items="clinics"
                          :item-value="item => item.clinic_id"
                          :item-text="item => item.clinic_name"
                          id="filter-clinic-names"
                          multiple
                          outlined
                          dense
                          chips
                          small-chips
                          hide-details
                        >
                          <template v-slot:no-data>
                            <p class="ma-0 py-3 px-2">
                              No clinics found.
                            </p>
                          </template>
                          <template v-slot:selection="data">
                            <v-chip
                              v-if="filteredClinicIdsByName.length == 1"
                              v-bind="data.attrs"
                              :input-value="data.item.clinic_id"
                              @click="data.select"
                              @click:close="removeClinicName(data.item)"
                              close
                            >
                              {{ data.item.clinic_name }}
                            </v-chip>
                            <span
                              v-if="
                                filteredClinicIdsByName.length > 1 &&
                                  data.index === 0
                              "
                            >
                              {{ filteredClinicIdsByName.length }} selected
                            </span>
                          </template></v-autocomplete
                        >
                      </v-col>
                      <v-col lg="8">
                        <label
                          for="filter-clinic-towns"
                          class="d-inline-flex mb-2 home-input-label font-weight-bold"
                          >Select county</label
                        >
                        <v-autocomplete
                          v-model="filteredClinicRegions"
                          :items="clinicRegions"
                          id="filter-clinic-regions"
                          multiple
                          outlined
                          dense
                          chips
                          small-chips
                          hide-details
                        >
                          <template v-slot:no-data>
                            <p class="ma-0 py-3 px-2">
                              No regions found.
                            </p>
                          </template>
                          <template v-slot:selection="data">
                            <v-chip
                              v-if="filteredClinicRegions.length == 1"
                              v-bind="data.attrs"
                              :input-value="data.item"
                              @click="data.select"
                              @click:close="removeClinicRegion(data.item)"
                              close
                            >
                              {{ data.item }}
                            </v-chip>
                            <span
                              v-if="
                                filteredClinicRegions.length > 1 &&
                                  data.index === 0
                              "
                            >
                              {{ filteredClinicRegions.length }} selected
                            </span>
                          </template></v-autocomplete
                        >
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto mb-8">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-card-title>
                        <span class="primary--text">Select clinic</span>
                      </v-card-title>
                      <div v-if="clinicsFiltered && clinicsFiltered.length">
                        <v-radio-group
                          v-model="selectedClinic"
                          v-on:change="onRadioGroupClicked"
                          class="ma-0"
                        >
                          <v-radio
                            v-for="clinic in clinicsFiltered"
                            :key="clinic.clinic_id"
                            :value="clinic"
                            v-on:keyup.enter="
                              selectedClinic = clinic;
                              onRadioGroupClicked();
                            "
                            color="primary"
                            class="mb-4 py-2 px-2 black--text"
                            :ripple="false"
                          >
                            <template slot="label">
                              <div class="flex-row">
                                <p class="ma-0 font-weight-bold">
                                  {{ clinic.clinic_name }}
                                </p>
                                <p class="ma-0 home-text-2">
                                  {{ clinic.clinic_address }}
                                </p>
                              </div>
                            </template></v-radio
                          >
                        </v-radio-group>
                      </div>
                      <div v-else>
                        <p class="py-2 px-4 text-h6">
                          No clinics found. Please change selected filters.
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="6" class="d-none d-lg-flex">
                      <div
                        id="google-map__container"
                        class="d-flex google-map__container"
                        v-if="clinicsFiltered && clinicsFiltered.length"
                        aria-label="Vaccination clinics map. Click here to open interactive map within the modal window."
                      ></div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <div class="text-right">
                <v-btn
                  @click="onAfterContinueClicked"
                  v-if="clinics"
                  class="v-btn--primary"
                  color="primary"
                  :disabled="!selectedClinic"
                >
                  Continue
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="mb-8" v-if="!clinicsLoading && (!bookingPossible)">
          <v-row>
            <v-col cols="12">
              <h3 class="mt-16 text-center text-subtitle-1">
                We are sorry, however, we do not have any slots available to book online at this time.<br>
                Our team will be in contact to schedule your appointment.<br>
                Please note we are currently experiencing a high volume of appointment requests.
              </h3>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import config from '@src/config';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { calculateDistance } from '@helpers/distanceCalculation.ts';
import useDateHelpers from '@helpers/useDateHelpers'
import PatientAppointmentBookingCallMeBack from '@components/appointment/booking/callMeBack/callMeBack.vue'

const { useDifferenceInYears } = useDateHelpers()
const distances = [
  { label: '5 miles', value: 5 },
  { label: '10 miles', value: 10 },
  { label: '15 miles', value: 15 },
  { label: '20 miles', value: 20 },
  { label: '25 miles', value: 25 },
  { label: '30+ miles', value: 0 }
];

export default Vue.extend({
  components: {
    VueGoogleAutocomplete,
    PatientAppointmentBookingCallMeBack
  },
  name: 'BookingClinics',
  props: {
    accessCode: {
      type: String,
      default: ''
    },
    appointment: {
      type: Object,
      default: () => {}
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    googleMapsApiKeyLoaded: false,
    distances: distances,
    noCallMeBack: config.webBookingNoCallMeBack,
    clinicsLoading: true,
    clinics: null,
    ignorePreferredClinicSearch: false,
    clinicsMapImageName: null,
    filteredClinicIdsByName: [],
    filteredClinicRegions: [],
    selectedPlace: null,
    selectedClinic: null,
    preferredClinicNotFound: true,
    distance: 0,
    showIconCurrentLocation: true,
    showIconClearLocation: false,
    googleMaps: null,
    googleMapsMarkers: [],
    additionalInfo: {},
    config: config
  }),
  mounted: function() {
    this.fetchAppointment();
    this.getAdditionalInfo();

    if (config.enableGoogleMaps) {
      this.loadGoogleMapsScript();
    }
  },
  computed: {
    ...mapGetters('booking', ['getAppointment']),
    ...mapGetters('booking', { getClinic: 'getClinic' }),
    patientAge() {
      const dateOfBirth = this.getAppointment.patient.dateOfBirth;
      const age = dateOfBirth ? useDifferenceInYears(new Date(), this.getAppointment.patient.dateOfBirth) : 0;
      return age;
    },
    bookingPossible () {
      return this.clinics && this.clinics.length
    },
    appointmentAccessCode() {
      return this.accessCode || this.$route.params.appointmentAccessCode.replace(/\W/g, '');
    },
    clinicsFiltered() {
      if (!this.clinics) {
        return [];
      }

      let clinicsFiltered = [...this.clinics];

      if (this.filteredClinicIdsByName.length) {
        clinicsFiltered = clinicsFiltered.filter(c =>
          this.filteredClinicIdsByName.includes(c.clinic_id)
        );
      }
      if (this.filteredClinicRegions.length) {
        clinicsFiltered = clinicsFiltered.filter(c =>
          this.filteredClinicRegions.includes(c.clinic_region)
        );
      }
      if (this.selectedPlace) {
        clinicsFiltered = clinicsFiltered.filter(c => {
          const distance = calculateDistance(
            c.clinic_lat,
            c.clinic_lng,
            this.selectedPlace.latitude,
            this.selectedPlace.longitude,
            'M'
          );
          if (this.distance === 0 || distance < this.distance) {
            return true;
          } else {
            return false;
          }
        });
      }

      this.$nextTick(() => {
        if (this.googleMapsApiKeyLoaded) {
          this.initGoogleMaps();
          this.addClinicsToGoogleMaps();
        }
      });

      return clinicsFiltered;
    },
    clinicRegions() {
      if (!this.clinics) {
        return [];
      }
      let clinicRegions = this.clinics
        .filter(c => c.clinic_region)
        .map(c => c.clinic_region);
      clinicRegions = [...new Set(clinicRegions)];
      clinicRegions = clinicRegions.sort();
      return clinicRegions;
    }
  },
  methods: {
    removeClinicName(clinic) {
      const index = this.filteredClinicIdsByName.indexOf(clinic.clinic_id);
      if (index >= 0) this.filteredClinicIdsByName.splice(index, 1);
    },
    removeClinicRegion(clinicRegion) {
      const index = this.filteredClinicRegions.indexOf(clinicRegion);
      if (index >= 0) this.filteredClinicRegions.splice(index, 1);
    },
    initGoogleMaps() {
      const google = window.google;
      this.googleMaps = new google.maps.Map(
        document.getElementById('google-map__container'),
        {
          center: { lat: 54.6626011, lng: -7.9231074 },
          zoom: 8
        }
      );
    },
    addClinicsToGoogleMaps() {
      const clinicsArr = this.clinicsFiltered;
      for (let i = 0; i < clinicsArr.length; i++) {
        const google = window.google;
        const clinic = clinicsArr[i];
        const coordinates = this.getClinicLocation(clinic);

        if (coordinates) {
          const marker = new google.maps.Marker({
            position: coordinates.center,
            map: this.googleMaps
          });

          const infoWindow = new google.maps.InfoWindow({
            content: clinic.clinic_name + '  '
          });

          (function(marker, infoWindow) {
            google.maps.event.addListener(marker, 'click', function() {
              infoWindow.open(this.googleMaps, marker);
            });
          })(marker, infoWindow);

          this.googleMapsMarkers.push(marker);
        }
      }
      this.fitClinicsInGoogleMaps();
    },
    getClinicLocation(clinic) {
      const latitude = clinic.clinic_lat;
      const longitude = clinic.clinic_lng;
      if (!latitude || !longitude) {
        return null;
      }
      const google = window.google;
      const latLng = new google.maps.LatLng(latitude, longitude);

      return { center: latLng, zoom: 15 };
    },
    fitClinicsInGoogleMaps() {
      const google = window.google;
      const bounds = new google.maps.LatLngBounds();
      for (let i = 0; i < this.googleMapsMarkers.length; i++) {
        bounds.extend(this.googleMapsMarkers[i].getPosition());
      }
      this.googleMaps.fitBounds(bounds);
    },
    loadGoogleMapsScript() {
      this.$loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${config.googleMapsAPIKey}&libraries=places`
      ).then(() => {
        this.googleMapsApiKeyLoaded = true;
      });
    },
    onGoogleAutocompletePlaceChanged(place) {
      this.selectedPlace = place;
    },
    onGoogleAutocompleteInputChanged(input) {
      if (!input.newVal) {
        this.selectedPlace = null;
        this.showIconCurrentLocation = true;
        this.showIconClearLocation = false;
      } else {
        this.showIconCurrentLocation = false;
        this.showIconClearLocation = true;
      }
    },
    onIconCurrentLocationClicked() {
      navigator.geolocation.getCurrentPosition(
        s => {
          this.$refs.fga.update('Your current location');
          this.selectedPlace = s.coords;
        },
        e => {
        }
      );
    },
    onIconClearLocationClicked() {
      this.$refs.fga.clear();
    },
    onRadioGroupClicked() {
      this.$store.commit('booking/setClinic', this.selectedClinic);
    },
    onAfterContinueClicked() {
      this.$emit('continue')
    },
    fetchAppointment() {
      this.clinicsLoading = true;
      this.$_rest.get(
        `/appointments/${this.appointment.id}`,
        {
          groups:
            'all,appointment_status,appointment_procedure_status,clinic_scan_room,appointment_procedure_procedure,procedure_procedure_clinics,appointment_appointment_procedures,appointment_patient,procedure_scan,procedure_referral_template,appointment_clinic,appointment_preferred_clinic'
        },
        async appointmentResponse => {
          appointmentResponse.data.appointmentProcedures = appointmentResponse.data.appointmentProcedures
            .filter(ap => ap.statusCode !== 'X' && ap.statusCode !== 'XR');

          this.$store.commit('booking/setAppointment', appointmentResponse.data);

          this.clinicsLoading = true;
          this.preferredClinicNotFound = false;

          try {
            let response = await this.fetchClinics(this.visible);
            let responseJSON = await response.json();
            this.clinics = Object.values(responseJSON.response.clinics);

            //when we move automatically to slots, and there is no preferred clinic
            //we fetch clinics again this time ignoring preferred clinic
            if (!this.clinics.length && !this.visible) {
              this.ignorePreferredClinicSearch = true;
              response = await this.fetchClinics(true);
              responseJSON = await response.json();
              this.clinics = Object.values(responseJSON.response.clinics);
            }

            if (this.clinics.length && !this.visible) {
              const preferredClinic = this.clinics
                .find(c => c.clinic_id === this.getAppointment.preferredClinic.id);

              if (!preferredClinic) {
                this.preferredClinicNotFound = true;
                this.clinicsLoading = false;
                return;
              }

              if (preferredClinic) {
                this.$store.commit('booking/setClinic', preferredClinic);
              }

              this.$emit('continue')
            }

            this.clinicsLoading = false;
          }
          catch {
            this.clinicsLoading = false;
            this.$_notify.error('Could not load clinics.');
          }
        },
        () => {
          this.$_notify.error('Could not get appointment.');
        },
        { prefixRoutesWithPatientId: true }
      );
    },
    async fetchClinics(ignorePreferredClinic) {
      return fetch(
        config.standaloneWebBookingUrl +
          '/init/?type=portal_schedule' +
          '&hash=' +
          config.webBookingOrgHash +
          '&appId=' +
          this.getAppointment.id +
          '&scanId=' +
          config.standaloneWebBookingScanId +
          '&regionId=' +
          config.standaloneWebBookingRegionId +
          '&procedureId=' +
          this.getAppointment.appointmentProcedures[0].procedure.id +
          '&age=' +
          this.patientAge +
          '&ignorePreferredClinic=' +
          ignorePreferredClinic +
          '&action=getClinics',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + sessionStorage.getItem(config.authTokenName)
          }
        }
      )
    },
    getAdditionalInfo () {
      if (this.appointment.appointmentProcedures.length > 0) {
        this.appointment.appointmentProcedures.forEach((ap) => {
          if (ap.procedure.scan.webBookingSlotsSelectionAdditionalInfo) {
            this.additionalInfo[ap.procedure.scan.code] = ap.procedure.scan.webBookingSlotsSelectionAdditionalInfo
          }
        })
      }
    }
  }
});
</script>

<style scoped>
.google-autocomplete {
  width: 100% !important;
  height: 40px !important;
  padding: 3px 0 !important;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  border-radius: 4px !important;
  text-indent: 10px;
}

.google-autocomplete:hover,
.google-autocomplete:focus-visible {
  padding: 3px 0 !important;
  outline: none;
}

.icon__current-location,
.icon__clear-location {
  position: absolute;
  top: 4px;
  right: 10px;
  z-index: 2;
}

.static-map__container {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-position: center top !important;
}

#google-map__container {
  width: 100%;
  height: 100%;
  min-height: 300px;
}
</style>
