<template>
  <div>
    <v-row v-if="getClinic" align="center" justify="center">
      <v-col>
        <patient-appointment-booking-call-me-back-info
          :clinic="getClinic"></patient-appointment-booking-call-me-back-info>
      </v-col>
    </v-row>
    <v-row v-if="config.bookingAdditionalInfo || additionalInfo">
      <v-col>
        <div class="body-2 text-left mb-2" v-if="config.bookingAdditionalInfo"
            v-html="config.bookingAdditionalInfo">
          </div>
        <div v-if="additionalInfo">
          <div class="text-left body-2 my-2" v-for="(v, k) in additionalInfo" v-bind:key="k">
            <span v-if="additionalInfo.length > 1"><strong>{{ k }}</strong><br/></span>
            <div v-html="v"></div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h4 class="text-center mt-4">
          <span>If you would prefer to speak to an agent please click on "Call me back to help book my appointment" below.</span>
        </h4>
      </v-col>
      <v-col>
        <patient-appointment-booking-call-me-back :appointment-id="getAppointment.id" class="mb-8"></patient-appointment-booking-call-me-back>
      </v-col>
    </v-row>
    <v-card :loading="slotsLoading" class="mx-auto">
      <template slot="progress">
        <v-progress-linear
          color="primary"
          indeterminate
        ></v-progress-linear>
      </template>
    </v-card>
    <v-row v-if="!slotsLoading" align="center" justify="center">
      <v-col>
        <v-btn @click="$emit('back')" class="mb-4" text outlined>Show alternative locations</v-btn>
      </v-col>
    </v-row>
    <slots-header
      v-if="!slotsLoading"
      v-bind:dates="availabilityDates"
      @prevDate="onHeaderPrevDate()"
      @nextDate="onHeaderNextDate()"
      class="mb-6"
    >
    </slots-header>
    <div v-if="!slotsLoading">
      <div v-if="availabilityFlattened.length">
        <div class="mb-4 py-4 px-8 slots__time--header">MORNING</div>
        <availability-row
          v-if="availabilityInHours(getMorningHours)"
          v-bind:hours="getMorningHours"
          v-bind:availabilityModel="availability"
        ></availability-row>
        <slots-not-available 
          v-else
          class="mb-4">
        </slots-not-available>
        <div class="mb-4 py-4 px-8 slots__time--header">AFTERNOON</div>
        <availability-row
          v-if="availabilityInHours(getAfternoonHours)"
          v-bind:hours="getAfternoonHours"
          v-bind:availabilityModel="availability"
        ></availability-row>
        <slots-not-available 
          v-else
          class="mb-4">
        </slots-not-available>
        <div class="mb-4 py-4 px-8 slots__time--header">EVENING</div>
        <availability-row
          v-if="availabilityInHours(getEveningHours)"
          v-bind:hours="getEveningHours"
          v-bind:availabilityModel="availability"
        ></availability-row>
        <slots-not-available 
          v-else
          class="mb-4">
        </slots-not-available>
      </div>
      <slots-not-available 
          v-else
          v-bind:iterationsOnSearchNextSlotsLimitReached="iterationsOnSearchNextSlotsLimitReached"
          v-bind:iterationsOnSearchNextSlotsLimitDays="iterationsOnSearchNextSlotsLimitDays"
          class="mb-4">
        </slots-not-available>
    </div>
    <v-alert v-if="showError" color="#CD1041" class="mb-10">
      <div class="px-12 py-10 white--text" v-html="errorMessage"></div>
    </v-alert>
    <div class="text-right">
      <v-btn
        @click="onAfterContinueClicked"
        v-if="!slotsLoading && getAvailability && false"
        ref="goToAvailabilityWithinHour"
        color="primary"
        class="white--text v-btn--primary"
        :disabled="!getAvailabilitySlot"
        :loading="schedulingAppointment"
      >
        Continue
      </v-btn>
    </div>
  </div>
</template>

<style scoped>
.slots__time--header {
  border-radius: 8px;
  background: rgba(0, 126, 147, 0.05);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.1em;
  color: var(--v-primary-base);
}
</style>

<script>
import config from '@src/config';
import Vue from 'vue';
import {mapGetters} from 'vuex';
import moment from 'moment/moment';
import useDateHelpers from '@helpers/useDateHelpers'
import PatientAppointmentBookingCallMeBack from '@components/appointment/booking/callMeBack/callMeBack.vue'
import PatientAppointmentBookingCallMeBackInfo from '@components/appointment/booking/callMeBack/callMeBackInfo.vue'
import SlotsHeader from '@components/appointment/booking/slots/_Header.vue';
import SlotsNotAvailable from '@components/appointment/booking/slots/_SlotsNotAvailable.vue';
import AvailabilityRow from '@components/appointment/booking/slots/_AvailabilityRow.vue';

const { useDifferenceInYears } = useDateHelpers()
const DAYS_IN_WEEK_DESKTOP = 5;
const DAYS_IN_WEEK_MOBILE = 2;
const MAX_DAYS_ITERATIONS_ON_SEARCH_NEXT_SLOTS = 40;

export default Vue.extend({
  components: {
    PatientAppointmentBookingCallMeBack,
    PatientAppointmentBookingCallMeBackInfo,
    SlotsHeader,
    SlotsNotAvailable,
    AvailabilityRow
  },
  name: 'BookingSlots',
  props: ['showBackButton'],
  data: () => ({
    showError: false,
    errorMessage: '',
    slotsLoading: true,
    noCallMeBack: config.webBookingNoCallMeBack,
    schedulingAppointment: false,
    getAvailabilityStartDate: null,
    getAvailabilityStartTime: null,
    availabilityStartDate: null,
    iterationsOnSearchNextSlots: 0,
    iterationsOnSearchNextSlotsLimitReached: false,
    additionalInfo: {},
    config: config
  }),
  computed: {
    ...mapGetters('booking', [
      'getAppointment',
      'getClinic',
      'getAvailability',
      'getAvailabilitySlot'
    ]),
    ...mapGetters('booking', {getClinic: 'getClinic'}),
    patientAge() {
      const dateOfBirth = this.getAppointment.patient.dateOfBirth;
      const age = dateOfBirth ? useDifferenceInYears(new Date(), this.getAppointment.patient.dateOfBirth) : 0;
      return age;
    },
    MOBILE() {
      return !this.$vuetify.breakpoint.lgAndUp;
    },
    DAYS_IN_WEEK () {
      return this.MOBILE ? DAYS_IN_WEEK_MOBILE : DAYS_IN_WEEK_DESKTOP;
    },
    iterationsOnSearchNextSlotsLimit() {
      return MAX_DAYS_ITERATIONS_ON_SEARCH_NEXT_SLOTS / this.DAYS_IN_WEEK;
    },
    iterationsOnSearchNextSlotsLimitDays() {
      return MAX_DAYS_ITERATIONS_ON_SEARCH_NEXT_SLOTS;
    },
    availability() {
      const availability = this.getAvailability;
      if (!availability) {
        return {};
      }
      const availabilityKeys = Object.keys(availability);
      const startDayKey = this.availabilityStartDate.format('YYYY-MM-DD');
      const startDayIndex = availabilityKeys.indexOf(startDayKey);
      const result = {};
      if (startDayIndex === -1) {
        return {};
      }
      for (let d = startDayIndex; d < startDayIndex + this.DAYS_IN_WEEK; d++) {
        const key = availabilityKeys[d];
        if (key in availability) {
          result[key] = availability[key];
        }
      }
      return result;
    },
    availabilityFlattened () {
      const availabilityArr = Object.values(this.availability);
      const slotsArr = availabilityArr.map(a => (a ? Object.values(a) : []));
      const slots = [].concat(...slotsArr);

      return slots;
    },
    availabilityDates () {
      const dates = Object.keys(this.availability);
      return dates;
    },
    getMinHour () {
      const hours = this.availabilityFlattened.map(a =>
        moment(a.slot_start, 'HH:mm:ss').format('H')
      );
      return Math.min(...hours);
    },
    getMaxHour () {
      const hours = this.availabilityFlattened.map(a =>
        moment(a.slot_start, 'HH:mm:ss').format('H')
      );
      return Math.max(...hours);
    },
    getMorningHours () {
      const morningHours = [];
      const maxHour = Math.min(11, this.getMaxHour);
      for (let i = this.getMinHour; i <= maxHour; i++) {
        morningHours.push(i);
      }
      return morningHours;
    },
    getAfternoonHours () {
      const afternoonHours = [];
      const minHour = Math.max(12, this.getMinHour);
      const maxHour = Math.min(17, this.getMaxHour);
      for (let i = minHour; i <= maxHour; i++) {
        afternoonHours.push(i);
      }
      return afternoonHours;
    },
    getEveningHours () {
      const eveningHours = [];
      const minHour = Math.max(18, this.getMinHour);
      for (let i = minHour; i <= this.getMaxHour; i++) {
        eveningHours.push(i);
      }
      return eveningHours;
    },
    getClinicAvailabilityStartDate () {
      return this.getClinic ? moment(this.getClinic.availability_date) : '';
    },
    getClinicAvailabilityStartTime () {
      return this.getClinic ? moment(this.getClinic.availability_start, 'HH:mm:ss') : '';
    },
    getSlotsUrl () {
      const durations = this.getAppointment.appointmentProcedures.map(ap => {     
        if (ap.isDurationManualOverride && !ap.enableOptimization) {
          const duration = Number(ap.duration) + Number(ap.durationExtraTime || 0);
          return duration;
        }
        else {
          const procedureClinic = ap.procedure.procedureClinics.filter(pc => pc.clinicId === this.getClinic.clinic_id)[0];
          const duration = Number(procedureClinic.procedureDuration || ap.procedure.duration || ap.duration) + Number(ap.durationExtraTime || 0);
          return duration;
        }
      });
      const durationSummed = durations.reduce(function (a, b) {
        return a + b;
      });

      return (
        config.standaloneWebBookingUrl +
        '/init/?type=portal_schedule' +
        '&hash=' +
        config.webBookingOrgHash +
        '&appId=' +
        this.getAppointment.id +
        '&regionId=' +
        config.standaloneWebBookingRegionId +
        '&clinicId=' +
        this.getClinic.clinic_id +
        '&procedureId=' +
        this.getAppointment.appointmentProcedures[0].procedure.id +
        '&appointmentDate=' +
        this.getAvailabilityStartDate.format('YYYY-MM-DD') +
        '&appointmentDate_Min=' +
        this.getAvailabilityStartDate.format('YYYY-MM-DD') +
        '&appointmentTime_Min=' +
        this.getAvailabilityStartTime.format('HH:mm:ss') +
        '&age=' +
        this.patientAge +
        '&slotDuration=' +
        durationSummed +
        '&action=getSlots'
      );
    }
  },
  mounted: async function() {
    this.setAvailabilityDates();
    this.getAdditionalInfo();

    await this.getSlots();
    this.getNextPageIfNoSlotsVisible();
  },
  watch: {
    getAvailabilitySlot: function (val) {
      if (val) {
        this.$emit('selected');
      }
    }
  },
  methods: {
    onAfterContinueClicked() {
      this.$emit('continue')
    },
    availabilityInHours(hours) {
      const availability = this.availabilityFlattened.filter(s => {
        const slotHour = moment(s.slot_start, 'HH:mm:ss').format('H');
        if (hours.includes(Number(slotHour))) {
          return true;
        } else {
          return false;
        }
      });
      return availability.length;
    },
    setAvailabilityDates() {
      if (!this.getClinic) {
        return
      }
      this.getAvailabilityStartDate = this.getClinicAvailabilityStartDate;
      this.getAvailabilityStartTime = this.getClinicAvailabilityStartTime;
      if (this.getAvailabilitySlot) {
        if (
          this.getAvailabilitySlot.date.isAfter(
            this.getClinicAvailabilityStartDate,
            'day'
          )
        ) {
          this.getAvailabilityStartDate = this.getAvailabilitySlot.date;
          this.getAvailabilityStartTime = moment('00:00:00', 'HH:mm:ss');
        }
      }

      if (this.MOBILE && this.getAvailabilityStartDate.isoWeekday() < 7) {
        this.availabilityStartDate = this.getAvailabilityStartDate.clone();
      } else if (this.MOBILE) {
        this.availabilityStartDate = this.getAvailabilityStartDate
          .clone()
          .isoWeekday(6);
      } else {
        this.availabilityStartDate = this.getAvailabilityStartDate
          .clone()
          .startOf('isoWeek');
      }
    },
    onHeaderPrevDate() {
      this.availabilityStartDate = this.availabilityStartDate
        .clone()
        .subtract(this.DAYS_IN_WEEK, 'days');
    },
    async onHeaderNextDate() {
      this.availabilityStartDate = this.availabilityStartDate
        .clone()
        .add(this.DAYS_IN_WEEK, 'days');

      if (this.ifAdditionalSlotsRequired()) {
        await this.getNextSlots();
      }

      this.getNextPageIfNoSlotsVisible();
    },
    ifAdditionalSlotsRequired() {
      const begIndexKey = this.availabilityStartDate.format('YYYY-MM-DD');
      const begIndex = Object.keys(this.getAvailability).indexOf(begIndexKey);
      if (
        begIndex === -1 ||
        begIndex + this.DAYS_IN_WEEK > Object.keys(this.getAvailability).length
      ) {
        return true;
      } else {
        return false;
      }
    },  
    async getNextPageIfNoSlotsVisible() {
      const daysWithSlotsVisible = Object.values(this.availability).flatMap(x=>x).length;

      if (daysWithSlotsVisible === 0 && this.iterationsOnSearchNextSlots < this.iterationsOnSearchNextSlotsLimit) {
        this.iterationsOnSearchNextSlots++;
        await this.onHeaderNextDate();
      }
      else if (this.iterationsOnSearchNextSlots === this.iterationsOnSearchNextSlotsLimit) {
        this.iterationsOnSearchNextSlotsLimitReached = true;
        this.iterationsOnSearchNextSlots = 0;
      }
      else {
        this.iterationsOnSearchNextSlots = 0;
      }
    },
    async getNextSlots() {
      this.getAvailabilityStartTime = moment('00:00:00', 'HH:mm:ss');
      this.getAvailabilityStartDate = this.getAvailabilityStartDate
        .clone()
        .add(1, 'weeks')
        .startOf('isoWeek');

      await this.getSlots();
    },
    async getSlots() {
      this.slotsLoading = true;

      const slotsJSON = await this.fetchSlots()
      const slots = await slotsJSON.json()
      const mergedAvailability = Object.assign(
        {},
        this.getAvailability || {},
        slots.response.availability
      );

      this.$store.commit('booking/setAvailability', mergedAvailability);
      this.slotsLoading = false;
    },
    fetchSlots() {
      return fetch(this.getSlotsUrl, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization:
            'Bearer ' + sessionStorage.getItem(config.authTokenName)
        },
        body: ''
      });
    },
    getAdditionalInfo () {
      if (this.getAppointment.appointmentProcedures.length > 0) {
        this.getAppointment.appointmentProcedures.forEach((ap) => {
          if (ap.procedure.scan.webBookingSlotsSelectionAdditionalInfo) {
            this.additionalInfo[ap.procedure.scan.code] = ap.procedure.scan.webBookingSlotsSelectionAdditionalInfo
          }
        })
      }
    }
  }
});
</script>
