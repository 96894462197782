<template>
  <simple-layout>
    <v-container style="max-width: 1024px;">
      <v-row justify="center">
        <v-col>
          <v-alert
            dense
            text
            type="success"
            class="pa-8"
          >
            <strong>Your appointment has successfully been rescheduled.</strong>
          </v-alert>
          <p class="mt-8 text-center">
            <v-btn
              color="primary"
              tile
              @click="$router.push('/')"
            >
              Return to appointments list
            </v-btn>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </simple-layout>

</template>

<script>
import SimpleLayout from '@router/layouts/simple.vue'

export default {
  name: 'AppointmentRescheduleThankYou',
  components: {
    SimpleLayout,
  },
  data () {
    return {
      loading: false
    }
  },
  mounted () {
    this.$store.commit('booking/setAppointment', null);
    this.$store.commit('booking/setClinic', null);
    this.$store.commit('booking/setAvailability', null);
    this.$store.commit('booking/setAvailabilitySlot', null);
    this.$store.commit('booking/setSlot', null);
    this.$store.commit('booking/setInvalidSlots', []);
  },
  methods: {
  }
}
</script>
