<template>
  <v-row justify="center">
    <v-col lg="6">
      <v-container>
        <h3 class="mb-4 primary--text">
          Choose your appointment time for {{ availabilityDate }}
        </h3>
        <v-radio-group
          v-model="selectedSlot"
          v-on:change="onRadioGroupClicked"
          class="my-0"
        >
          <v-radio
            v-for="slot in slotsWithinHour"
            :key="slot.slot_start"
            :label="slot.slot_start"
            :value="slot"
            :disabled="schedulingAppointment"
            v-on:keyup.enter="
              selectedSlot = slot;
              onRadioGroupClicked();
            "
            color="#006078"
            class="py-2 black--text"
            :ripple="false"
          >
            <template slot="label">
              <span class="mr-2">{{ getSlotTime(slot) }}</span>
              <span class="notranslate" v-if="$vuetify.breakpoint.lgAndUp">{{
                getSlotAM_PM(slot)
              }}</span>
            </template></v-radio
          >
        </v-radio-group>
        <v-alert v-if="showError" color="#CD1041" class="mb-10">
          <div class="px-12 py-10 white--text" v-html="errorMessage"></div>
        </v-alert>
        <v-row>
          <v-col cols="6">
            <v-btn
              v-if="!hideCancelButton"
              @click="$emit('cancel')"
              :disabled="schedulingAppointment || confirmingAppointment"
              color="primary"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              @click="onAfterContinueClicked"
              :loading="schedulingAppointment || confirmingAppointment"
              :disabled="!getSlot"
              color="primary"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<style scoped></style>

<script>
import config from '@src/config';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import moment, { duration } from 'moment/moment';

export default Vue.extend({
  components: {},
  name: 'BookingSlots',
  props: ['hideCancelButton'],
  data: () => ({
    selectedSlot: null,
    showError: false,
    errorMessage: '',
    schedulingAppointment: false,
    confirmingAppointment: false
  }),
  computed: {
    ...mapGetters('booking', [
      'getAppointmentAccessCode',
      'getAppointment',
      'getClinic',
      'getAvailability',
      'getAvailabilitySlot',
      'getSlot',
      'getInvalidSlots'
    ]),
    availabilityDate () {
      return moment(this.getAvailabilitySlot.date).format('MMMM Do, YYYY');
    },
    getConfirmAppointmentUrl() {
      return (
        config.standaloneWebBookingUrl +
        '/init/?type=portal_schedule' +
        '&hash=' +
        config.webBookingOrgHash +
        '&scanId=' +
        config.standaloneWebBookingScanId +
        '&regionId=' +
        config.standaloneWebBookingRegionId +
        '&appId=' +
        this.getAppointment.id +
        '&appProcId=' +
        this.getAppointment.appointmentProcedures[0].id +
        '&action=confirmAppointment'
      );
    },
    slotsWithinHour() {
      const availabilitySlotDateKey = this.getAvailabilitySlot.date.format(
        'YYYY-MM-DD'
      );
      const slotsPerDay = this.getAvailability[availabilitySlotDateKey];
      const slotsWithinHour = Object.values(slotsPerDay).filter(slot => {
        //check if slot is not in the invalid slots
        if (
          this.getInvalidSlots.filter(
            s =>
              s.slot_start === slot.slot_start &&
              s.availability_date === slot.availability_date
          ).length
        ) {
          return false;
        }

        //check if slot is within the selected hour
        const slotHour = moment(slot.slot_start, 'hh:mm:ss').format('H');
        const availabilitySlotHourString = this.getAvailabilitySlot.hour;
        if (Number(slotHour) === Number(availabilitySlotHourString)) {
          return true;
        } else {
          return false;
        }
      });

      return slotsWithinHour;
    }
  },
  mounted: function() {
    if (this.getSlot) {
      const ifSlotOnTheList = this.slotsWithinHour.filter(
        s =>
          s.slot_start === this.getSlot.slot_start &&
          s.availability_date === this.getSlot.availability_date
      ).length;
      if (ifSlotOnTheList) {
        this.selectedSlot = this.getSlot;
      }
    }
  },
  methods: {
    async getAppointmentProcedureDuration(appointmentProcedure, durationShift) {
      let duration;
      if (appointmentProcedure.isDurationManualOverride && !appointmentProcedure.enableOptimization) {
        duration = Number(appointmentProcedure.duration) + Number(appointmentProcedure.durationExtraTime || 0);
      }
      else if (appointmentProcedure.enableOptimization) {
        duration = await this.getOptimizationDuration(appointmentProcedure, durationShift);
        duration = duration ? Number(duration) + Number(appointmentProcedure.durationExtraTime || 0) : undefined;
      }

      if (!duration) {
        const procedureClinic = appointmentProcedure.procedure.procedureClinics.filter(pc => pc.clinicId === this.getClinic.clinic_id)[0];
        duration = Number(procedureClinic.procedureDuration || appointmentProcedure.procedure.duration || appointmentProcedure.duration) + Number(appointmentProcedure.durationExtraTime || 0);
      }

      return duration;
    },
    getOptimizationDurationUrl(appointmentProcedure, durationShift) {
      const slotTime = moment(this.getSlot.slot_start, 'HH:mm:ss');
      const slotTimeShifted = slotTime
        .clone()
        .add(durationShift, 'minutes');

      return (
        config.standaloneWebBookingUrl +
        '/init/?type=portal_schedule' +
        '&hash=' +
        config.webBookingOrgHash +
        '&appId=' +
        this.getAppointment.id +
        '&appProcId=' +
        appointmentProcedure.id +
        '&clinicScanRoomId=' +
        this.getSlot.clinic_scan_room_id +
        '&slotDate=' +
        this.getSlot.availability_date +
        '&slotTime=' +
        slotTimeShifted.format('HH:mm:ss')+
        '&action=getOptimizationDuration'
      );
    },
    getScheduleAppointmentUrl(appointmentProcedure, duration, durationShift) {
      const slotTime = moment(this.getSlot.slot_start, 'HH:mm:ss');
      const slotTimeShifted = slotTime
        .clone()
        .add(durationShift, 'minutes');

      return (
        config.standaloneWebBookingUrl +
        '/init/?type=portal_schedule' +
        '&hash=' +
        config.webBookingOrgHash +
        '&scanId=' +
        config.standaloneWebBookingScanId +
        '&regionId=' +
        config.standaloneWebBookingRegionId +
        '&appId=' +
        this.getAppointment.id +
        '&appProcId=' +
        appointmentProcedure.id +
        '&clinicScanRoomId=' +
        this.getSlot.clinic_scan_room_id +
        '&slotDate=' +
        this.getSlot.availability_date +
        '&slotTime=' +
        slotTimeShifted.format('HH:mm:ss')+
        '&slotDuration=' +
        duration +
        '&action=scheduleAppointment'
      );
    },
    getSlotTime(slot) {
      return moment(slot.slot_start, 'HH:mm:ss')
        .format('HH:mm')
        .toUpperCase();
    },
    getSlotAM_PM(slot) {
      return moment(slot.slot_start, 'HH:mm:ss')
        .format('a')
        .toUpperCase();
    },
    onRadioGroupClicked() {
      this.$store.commit('booking/setSlot', this.selectedSlot);
      if (!config.webBookingScheduleOnConfirm) {
        this.scheduleAppointment();
      }
    },
    async onAfterContinueClicked() {
      if (config.webBookingScheduleOnConfirm) {
        await this.scheduleAppointment();
      }
      this.confirmAppointment();
    },
    async getOptimizationDuration(appointmentProcedure, durationShift) {
      this.schedulingAppointment = true;
      this.showError = false;
      this.errorMessage = '';

        try {
          const getOptimizationDurationtUrl = this.getOptimizationDurationUrl(appointmentProcedure, durationShift);
          
          const getOptimizationDurationResponse = await fetch(getOptimizationDurationtUrl, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization:
                'Bearer ' + sessionStorage.getItem(config.authTokenName)
            },
            redirect: 'follow',
            body: ''
          });
          const getOptimizationDurationtJson = await getOptimizationDurationResponse.json();

          this.schedulingAppointment = false;

          return getOptimizationDurationtJson?.response?.optimizationDuration;
        }
        catch {
          this.schedulingAppointment = false;
          this.onScheduleError();
        }
    },
    async scheduleAppointment() {
      this.schedulingAppointment = true;
      this.showError = false;
      this.errorMessage = '';

      let index = 0
      let durationShift = 0;

      for (index; index < this.getAppointment.appointmentProcedures.length; index++) {
        const appointmentProcedure = this.getAppointment.appointmentProcedures[index];
        try {
          const duration = await this.getAppointmentProcedureDuration(appointmentProcedure, durationShift);
          const scheduleAppointmentUrl = this.getScheduleAppointmentUrl(appointmentProcedure, duration, durationShift);
          
          const scheduleResponse = await fetch(scheduleAppointmentUrl, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization:
                'Bearer ' + sessionStorage.getItem(config.authTokenName)
            },
            redirect: 'follow',
            body: ''
          });
          const scheduleResponseJson = await scheduleResponse.json();

          this.schedulingAppointment = false;
          
          durationShift += duration;

          if (scheduleResponseJson.status === 'S' && scheduleResponseJson.response.status) {
            this.onScheduleSuccess();
          } else {
            this.onScheduleError();
            return false;
          }
        }
        catch {
          this.schedulingAppointment = false;
          this.onScheduleError();
          return false;
        }
        
      }
    },
    confirmAppointment() {
      this.confirmingAppointment = true;
      this.showError = false;
      this.errorMessage = '';
      fetch(this.getConfirmAppointmentUrl, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization:
            'Bearer ' + sessionStorage.getItem(config.authTokenName)
        },
        redirect: 'follow',
        body: ''
      })
        .then(response => response.json())
        .then(response => {
          this.confirmingAppointment = false;
          if (response.status === 'S' && response.response.status) {
            this.onConfirmSuccess();
          } else {
            this.onConfirmError();
          }
        })
        .catch(e => {
          this.confirmingAppointment = false;
          this.onConfirmError();
        });
    },
    onScheduleSuccess() {
      this.slotScheduled = true;
    },
    onScheduleError() {
      const invalidSlots = [...this.getInvalidSlots];
      invalidSlots.push(this.selectedSlot);
      this.$store.commit('booking/setInvalidSlots', invalidSlots);
      this.$store.commit('booking/setSlot', null);

      this.selectedSlot = null;
      this.showError = true;
      this.errorMessage =
        'We are sorry, but the selected slot is no longer available. The list of available slots will now refresh. Please select an alternative time for your appointment.';
    },
    onConfirmSuccess() {
      this.$emit('confirm')
    },
    onConfirmError() {
      this.showError = true;
      this.errorMessage =
        'We are sorry, we could not confirm your appointment. Please try again.';
    }
  }
});
</script>
