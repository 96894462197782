<template>
  <v-container
    style="min-height: 460px;"
  >
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    />
    <v-row
      v-if="!loading"
    >
      <v-col
        v-if="questions.length"
        cols="12"
        class="py-0 my-0"
      >
        <div class="subtitle-1 text-center font-weight-bold">
          {{ $_t('Please answer the safety questions relating to your examination ') }}: {{ procedures }}
        </div>
        <div class="body-2 my-2" v-if="config.safetyQuestionsAdditionalInfo" v-html="config.safetyQuestionsAdditionalInfo"></div>
        <div v-if="additionalInfo">
          <div class="body-2 my-2" v-for="(v, k) in additionalInfo" v-bind:key="k">
            <span v-if="additionalInfo.length > 1"><strong>{{k}}</strong><br/></span>
            <div v-html="v"></div>
          </div>
        </div>
      </v-col>
      <v-col
        v-for="question in questions"
        :key="question.questionId"
        ref="questions"
        cols="12"
        sm="12"
      >
        <h6
            class="text-subtitle-1"
            style="width: 100%"
        >
          {{ $_t(question.name) }} {{ question.isMandatory ? '*' : '' }}
        </h6>
        <template v-if="question.type === 'FREETEXT'">
          <v-text-field
            v-model="question.answer"
            :label="$_t('safetyQuestions.your_answer')"
            outlined
            @change="changeFreetext(question)"
          />
        </template>
        <template v-if="question.type === 'SINGLE_SELECT'">
          <div class="">
            <v-spacer />
            <v-btn-toggle

              v-model="question.answer"
              shaped
              max="200"
              @change="changeSelectSingle(question)"
              style="display: block"
            >
              <div
                v-for="answer in question.answers"
                :key="answer.id"
                :style="{display: (answersInline(question) ? 'inline' : 'block')}"
              >

                <v-btn
                  :value="answer"
                  color="primary"
                  outlined
                  class="mr-1"
                  :min-width="answersInline(question) ? '120px' : '245px'"
                  :style="question.answer && question.answer.id === answer.id ? 'background:#ddffff' : ''"
                >
                  {{ $_t(answer.caption) }}
                </v-btn>
              </div>
            </v-btn-toggle>
          </div>
        </template>
        <template v-if="question.type === 'DATE'">
          <v-menu
            v-model="question.picker"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="question.answer"
                :label="$_t('Select Date')"
                prepend-icon="mdi-calendar"
                :close-on-content-click="true"
                :rules="[rules.required]"
                required
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
              />
            </template>
            <v-date-picker
              v-model="question.answer"
              @change="changeDate(question)"
              @input="question.picker = false"
            />
          </v-menu>
        </template>
        <v-divider class="mt-2" />
      </v-col>
      <v-col
        cols="12"
        v-if="!loading && !questions.length"
      >
        <div class="text-center subtitle-1">
          {{ $_t('There are no safety questions for your examination') }}: {{ procedures }}. {{ $_t('You can proceed to the the next step') }}.
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import config from '@config'
import moment from 'moment'

export default {
  name: 'AppointmentSafetyQuestions',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    appointment: {
      type: Object,
      default: () => {}
    },
    patientId: {
      type: String
    },
    complete: {
      type: Boolean
    },
    fetchRoute: {
      type: String
    },
    saveRoute: {
      type: String
    },
    showWarning: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      questions: [],
      rules: { required: value => !!value || 'Required.' },
      loading: true,
      config: config,
      additionalInfo: {}
    }
  },
  computed: {
    ...mapGetters('app', { currentPatient: 'currentUser' }),
    procedures () {
      return this.appointment.appointmentProcedures.filter(appointmentProcedure => !['X', 'XR'].includes(appointmentProcedure.statusCode))
        .map(appointmentProcedure => appointmentProcedure.procedure.name).join(', ')
    },
    currentPatientId () {
      return this.patientId || this.currentPatient && this.currentPatient.id
    }
  },
  mounted () {
    this.init()
    this.getAdditionalInfo()
    this.getDataFromRestApi()
  },
  methods: {
    answersInline(question) {
      if (typeof question.answers !== 'undefined' && Array.isArray(question.answers) && question.answers.reduce((sum, val) => sum + (val.caption ? val.caption.length : 0), 0) < 9) {
        return true
      }
      return false
    },
    getDataFromRestApi () {
      this.loading = true
      this.questions = []
      this.$_rest.get(
        this.fetchRoute,
        { groups: 'all,appointment_safety_question_safety_question,safety_question_type,safety_question_answers,safety_question_answer' },
        response => {
          if (response.data) {
            this.questions = Object.values(response.data)
              .filter(el => el.safetyQuestion.isActive)
              .sort((a, b) => {
                  return Number.parseFloat(a.safetyQuestion.displayOrder) > Number.parseFloat(b.safetyQuestion.displayOrder) ? 1 : -1
              })
              .map(el => {
                return {
                  questionId: el.id,
                  isMandatory: el.safetyQuestion.isMandatory,
                  displayOrder: el.safetyQuestion.displayOrder,
                  name: el.safetyQuestion.name,
                  type: el.safetyQuestion.typeCode,
                  answers: el.safetyQuestion.safetyQuestionAnswers.sort((a, b) => Number.parseFloat(a.id) >= Number.parseFloat(b.id) ? 1 : -1),
                  answer: this.createAnswer(el)
                }
              })

          }
          this.loading = false
        },
        error => {
          this.loading = false
          this.$_notify.error(error)
        })
    },
    createAnswer(el) {
      switch (el.safetyQuestion.typeCode) {
        case 'FREETEXT':
          return el.safetyQuestionAnswerFreetext
        case 'SINGLE_SELECT':
          return el.safetyQuestionAnswer ? el.safetyQuestionAnswer : null
        case 'DATE':
          return el.safetyQuestionAnswerDate ? moment(el.safetyQuestionAnswerDate).format('YYYY-MM-DD') : null
        default:
          return null
      }
    },
    init () {
      this.$root.$emit('scrollToTop')
    },
    saveAnswer (question) {
      this.insurerLoading = true
      const params = {}

      if (typeof question.answer === 'object' && !Array.isArray(question.answer) && question.answer ? question.answer.caption : question.answer) {
        if(question.type === 'FREETEXT' ) {
          params['answer'] = question.answer
        } else if(question.type === 'DATE') {
            params['safetyQuestionAnswerDate'] = moment(new Date(question.answer)).toISOString()
        } else {
          params['answer'] = question.answer.caption
          params['safetyQuestionAnswerId'] = question.answer.id
        }
      } else {
        params['answer'] = question.answer

      }
      this.$_rest.put(
      this.saveRoute,
      params, question.questionId,
      undefined,
      error => {
          // this.$_notify.error(error)
        }
      )
    },
    changeFreetext (question) {
      this.saveAnswer(question)
    },
    changeSelectSingle (question) {
      this.saveAnswer(question)
    },
    changeDate (question) {
      this.saveAnswer(question)
    },
    emitComplete (questions) {
      this.$emit('update:complete', !!(!questions.length && !this.loading || questions.length && !questions.find(el => el.isMandatory && !el.answer)))
    },
    goTo (questionIndex) {
      this.$vuetify.goTo(this.$refs.questions[questionIndex])
    },
    getAdditionalInfo() {
      if (this.appointment.appointmentProcedures.length > 0) {
        this.appointment.appointmentProcedures.forEach((ap) => {
          if (ap.procedure.scan.webBookingSafetyQuestionsAdditionalInfo ) {
            this.additionalInfo[ap.procedure.scan.code] = ap.procedure.scan.webBookingSafetyQuestionsAdditionalInfo
          }
        })
      }
    }
  },
  watch: {
    appointment () {
      if (this.appointment && this.appointmentId) {
        this.getDataFromRestApi()
      }
    },
    questions: {
      handler: function (questions) {
        this.$emit('input', questions)
        this.emitComplete(questions)
      },
      deep: true
    },
    loading (val) {
      this.emitComplete(this.questions)
      if (val) {
        this.$emit('loadingStart')
      } else {
        this.$emit('loadingEnd')
      }
    }
  }
}
</script>
