<template>
  <div class="text-center" v-if="clinic">
    <p>{{ $_t('You are booking your appointment at') }}:</p>
    <p>
      <span><strong>{{ clinic.clinic_name }}</strong></span><br>
      <span v-if="clinic.clinic_address">{{ clinic.clinic_address }}</span>
    </p>
  </div>
</template>

<script>
import Vue from 'vue'
import config from '@config'

export default Vue.extend({
  components: {},
  name: 'BookingClinics',
  props: {
    clinic: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    config: config
  }),
  mounted () {
  },
  methods: {
  }
});
</script>
