<template>
  <v-container>
    <v-autocomplete
      v-model="model"
      :items="locations"
      item-value="id"
      item-text="name"
      :loading="loading"
      :required="mandatory"
      :rules="checkRules"
      :error="showError"
      clearable
    />
  </v-container>
</template>

<script>
export default {
  name: 'PatientLocationDropdown',
  props: {
    value: {
      type: String,
      defualt:undefined
    },
    mandatory: {
      type:Boolean,
      defualt: false
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    checkRules(){
      if(this.mandatory) {
      return (this.model) ? [] : [this.$_t('Required')]

      } else {
        return []
      }
    },
    showError(){
      if(this.mandatory) {
        return (this.model) ? false : true

      } else {
        return false
      }
    }

  },
  data () {
    return {
      locations: [],
      loading: false
    }
  },
  mounted () {
    this.getLocations()
  },
  methods: {
    getLocations () {
      this.loading = true
      this.$_rest.get(`/practitioners/${ this.$store.getters['app/currentUserId'] }/patient-locations`, undefined, response => {
        this.loading = false
        this.locations = response.data
      }, error => {
        this.loading = false
        this.$_notify.error(error)
      }, {
        prefixRoutesWithOrganizationId: true
      })
    }
  }
}
</script>

<style scoped>

</style>
