<template>
  <simple-layout>
    <v-container style="max-width: 1024px;">
      <v-row justify="center">
        <v-col lg="6">
          <v-container class="ma-0 pa-0">
            <v-btn
              @click="$router.push('/reschedule/slots1')"
              color="primary"
              class="ml-3 white--text v-btn--primary"
            >
              <v-icon class="mr-4">mdi-arrow-left-thin-circle-outline</v-icon>
              <span>Back to hour selection</span>
            </v-btn>
          </v-container>
        </v-col>
      </v-row>
      <patient-appointment-booking-slots-within-hour
        @confirm="onSlots2Continue"
        :hideCancelButton="true"
      />
    </v-container>
  </simple-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import SimpleLayout from '@router/layouts/simple.vue'
import PatientAppointmentBookingSlotsWithinHour from '@components/appointment/booking/slots/SlotsWithinHour.vue'

export default {
  name: 'AppointmentRescheduleSlots2',
  components: {
    SimpleLayout,
    PatientAppointmentBookingSlotsWithinHour
  },
  data () {
    return {
      loading: false
    }
  },
  mounted () {
    if (!this.getAvailability) {
      this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters('booking', [
      'getAvailability'
    ]),
  },
  methods: {
    onSlots2Continue() {
      this.$router.push('/reschedule/confirmation')
    },
    onSlots2Cancel() {
      //this.showSlots1 = true;
      //this.showSlots2 = false;
    }
  }
}
</script>
