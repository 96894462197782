<template>
  <div>
    <div class="text-center">
      <v-btn @click="onRequestCallMeBackClicked" color="primary" outlined small>
        {{$_t('Call me back to help book my appointment')}}
      </v-btn>
    </div>
    <v-dialog v-model="dialogCallMeBack" max-width="800" max-height="600" :persistent="callbackSuccessful">
      <v-card :loading="dialogCallMeBackLoading">
        <v-card-text>
          <div v-if="!callbackConfirmed" class="pt-8 pb-4">
            <h3 class="mb-4">{{$_t('Do you want to send a callback request?')}}</h3>
            <p class="mb-2">{{$_t('Our team will be in contact to schedule your appointment.')}}</p>
            <p>{{$_t('Please note we are currently experiencing a high volume of appointment requests.')}}</p>
          </div>
          <div v-if="callbackSuccessful" class="pt-8 text-center">
            <h3>{{$_t('Thank you. Your callback request has been successfully sent.')}}</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!callbackConfirmed && !callbackSuccessful" @click="onConfirmCallMeBackClicked" :loading="dialogCallMeBackLoading" color="#006078" text>
            {{$_t('Request callback')}}
          </v-btn>
          <v-btn color="#006078" text @click="dialogCallMeBack = false" v-if="!callbackSuccessful">
            {{$_t('Close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
</style>

<script>
import config from '@src/config';
import Vue from 'vue';

export default Vue.extend({
  components: {
  },
  name: 'BookingClinics',
  props: ['appointmentId', 'requestCallbackOnMounted'],
  data: () => ({
    dialogCallMeBack: null,
    dialogCallMeBackLoading: false,
    callbackConfirmed: false,
    callbackSuccessful: false
  }),
  mounted: function() {
    if (this.requestCallbackOnMounted) {
      this.saveCallMeBack();
    }
  },
  methods: {
    onRequestCallMeBackClicked() {
      this.dialogCallMeBack = true;
    },
    onConfirmCallMeBackClicked() {
      this.callbackConfirmed = true;
      this.saveCallMeBack();
    },
    saveCallMeBack() {
      this.dialogCallMeBackLoading = true;
      fetch(
        config.standaloneWebBookingUrl +
          '/init/?type=portal_schedule' +
          '&hash=' +
          config.webBookingOrgHash +
          '&appId=' +
          this.appointmentId +
          '&action=saveCallMeBack',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + sessionStorage.getItem(config.authTokenName)
          }
        }
      )
      .then(response => response.json())
      .then(response => {
        this.dialogCallMeBackLoading = false;
        this.callbackSuccessful = true;
      })
      .catch(e => {
        this.$_notify.error('Could not process call me back request. Please try again.');
        this.callbackSuccessful = false;
        this.dialogCallMeBackLoading = false;
      });
    }
  }
});
</script>
