<template>
  <simple-layout>
    <v-container style="max-width: 1024px;">
      <v-row justify="center">
        <v-col>
          <v-btn
            @click="$router.push('/')"
            color="primary"
            class="ml-3 mb-4 white--text v-btn--primary"
          >
            <v-icon class="mr-4">mdi-arrow-left-thin-circle-outline</v-icon>
            <span>Back to my appointments</span>
          </v-btn>
          <patient-appointment-booking-clinics
            v-if="showClinics"
            :appointment="appointment"
            :visible="true"
            @continue="onClinicsContinue"
          />
        </v-col>
      </v-row>
    </v-container>
  </simple-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import SimpleLayout from '@router/layouts/simple.vue'
import PatientAppointmentBookingClinics from '@components/appointment/booking/clinics/Clinics.vue'

export default {
  name: 'AppointmentReschedule',
  components: {
    SimpleLayout,
    PatientAppointmentBookingClinics
  },
  data () {
    return {
      loading: false,
      appointment: null,
      showClinics: false
    }
  },
  mounted () {
    if (!this.$route.params.appointmentId) {
      this.$router.push('/')
    }
    this.getData()
  },
  computed: {
    ...mapGetters('booking', [
      'getAppointment'
    ]),
  },
  methods: {
    getData () {
      this.loading = true
      this.$_rest.get(`/patients/${this.$store.getters['app/currentUserId']}/appointments?groups=all,appointment_appointment_procedures,appointment_clinic,appointment,appointment_procedure_procedure,appointment_status,appointment_procedure_parent_appointment_procedure&orderBy[date]=desc`, {}, response => {
        this.loading = false;
        const appointmentsFiltered = response.data.filter((apt) => apt.id === this.$route.params.appointmentId);
        if (!appointmentsFiltered.length) {
          return;
        }
        else {
          this.appointment = appointmentsFiltered[0];
          this.showClinics = true;
        }
      }, error => {
        this.loading = false
        this.$_notify.error(error)
      })
    },
    onClinicsContinue() {
      this.$router.push('/reschedule/slots1')
    },
  }
}
</script>
