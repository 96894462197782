<template>
  <div ref="buttonWrapper">
    <v-btn
      v-if="available"
      @click="onAfterSlotClicked($event);"
      :class="active ? 'v-btn--active' : 'v-btn--enabled'"
      :aria-label="label"
    >
      <span>Available</span>
    </v-btn>
    <v-btn v-else :disabled="true" class="v-btn--disabled">
      <span>Full</span>
    </v-btn>
  </div>
</template>

<style scoped>
.v-application .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background: white !important;
  color: #808080 !important;
  border: 1px solid #c0c0c0;
  opacity: 1 !important;
}

.v-btn--enabled {
  width: 100% !important;
  min-width: 120px !important;
  height: 60px !important;
  background: white !important;
  box-shadow: none !important;
  color: var(--v-primary-base) !important;
  border: 1px solid var(--v-primary-base) !important;
  border-radius: 10px !important;
  font-size: 15px !important;
  line-height: 20px !important;
}

.v-btn--active {
  width: 100% !important;
  min-width: 120px !important;
  height: 60px !important;
  background: var(--v-primary-base) !important;
  box-shadow: none !important;
  color: #ffffff !important;
  border: 1px solid #f5f5f5;
  border-radius: 10px !important;
  font-size: 15px !important;
  line-height: 20px !important;
}

.v-btn--enabled:hover,
.v-btn--enabled:active,
.v-btn--active:hover,
.v-btn--active:active {
  color: #ffffff !important;
}

.v-btn--disabled {
  width: 100% !important;
  min-width: 120px !important;
  height: 60px !important;
  background: white !important;
  box-shadow: none !important;
  color: #cccccc !important;
  border: 1px solid #f5f5f5;
  border-radius: 10px !important;
  font-size: 15px !important;
  line-height: 20px !important;
}

/* < extra lg */
@media (max-width: 1904px) {
  .v-btn--enabled, .v-btn--active, .v-btn--disabled  {
    width: 100% !important;
    min-width: 100px !important;
    max-width: 100px !important;
    font-size: 15px !important;
    line-height: 15px !important;
  }
}

/* < lg */
@media (max-width: 1264px) {
  .v-btn--enabled, .v-btn--active, .v-btn--disabled  {
    width: 100% !important;
    min-width: 90px !important;
    max-width: 90px !important;
    font-size: 15px !important;
    line-height: 15px !important;
  }
}

.v-btn--enabled:hover::before,
.v-btn--active:hover::before {
  background: var(--v-primary-base) !important;
  color: #ffffff !important;
  opacity: 1 !important;
}

.v-btn /deep/ .v-btn__content {
  font-style: normal !important;
  font-weight: normal !important;
  text-transform: none !important;
}
</style>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {},
  name: 'SlotButton',
  props: ['available', 'date', 'hour', 'hoursSlotLabel'],
  data: () => ({
    selected: false
  }),
  computed: {
    ...mapGetters('booking', ['getAvailabilitySlot']),
    active() {
      return (
        this.getAvailabilitySlot &&
        this.getAvailabilitySlot.date.isSame(this.date) &&
        this.getAvailabilitySlot.hour === this.hour
      );
    },
    label() {
      return `Book visit between ${this.hoursSlotLabel} on ${this.date.format('dddd, DD MMMM YYYY')}`
    }
  },
  methods: {
    onAfterSlotClicked($event) {
      this.$store.commit('booking/setAvailabilitySlot', {
        date: this.date,
        hour: this.hour
      });

      Vue.nextTick(() => {
        if (this.$refs.buttonWrapper) {
          this.$refs.buttonWrapper.focus();
        }
        document.activeElement.blur();
      });
    }
  }
});
</script>
