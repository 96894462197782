export default {
  appTitle: process.env.VUE_APP_TITLE,
  apiUrl: process.env.VUE_APP_URL_API,
  enableGoogleMaps: process.env.VUE_APP_ENABLE_GOOGLE_MAPS,
  googleMapsAPIKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  keyRecaptchaSite: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  authClientId: process.env.VUE_APP_AUTH_CLIENT_ID,
  authTokenName: process.env.VUE_APP_AUTH_TOKEN_NAME,
  authEndpoint: process.env.VUE_APP_AUTH_ENDPOINT,
  authLogoutEndpoint: process.env.VUE_APP_AUTH_LOGOUT_ENDPOINT,
  authBasicEndpoint: process.env.VUE_APP_AUTH_BASIC_ENDPOINT,
  authBasicLogoutEndpoint: process.env.VUE_APP_AUTH_BASIC_LOGOUT_ENDPOINT,
  authScope: process.env.VUE_APP_AUTH_SCOPE,
  authRedirectUrl: process.env.VUE_APP_AUTH_REDIRECT_URL,
  appointmentCentreLink: process.env.VUE_APP_APPOINTMENT_CENTRE_LINK,
  loadingMessagesEnabled: process.env.VUE_APP_LOADING_MESSAGES_ENABLED === 'true',
  favicon: process.env.VUE_APP_FAVICON,
  privacyPolicyLink: process.env.VUE_APP_PRIVACY_POLICY_LINK,
  showPatientDetails: process.env.VUE_APP_SHOW_PATIENT_DETAILS === 'true',
  patientEmailMandatory: process.env.VUE_APP_PATIENT_EMAIL_MANDATORY === 'true',
  patientEmailHide: process.env.VUE_APP_PATIENT_EMAIL_MANDATORY === 'hide',
  newAppointmentComponents: process.env.VUE_APP_PATIENT_NEW_APPOINTMENT_COMPONENTS_CSV ? process.env.VUE_APP_PATIENT_NEW_APPOINTMENT_COMPONENTS_CSV : '',
  confirmedAppointmentComponents: process.env.VUE_APP_PATIENT_CONFIRMED_APPOINTMENT_COMPONENTS_CSV ? process.env.VUE_APP_PATIENT_CONFIRMED_APPOINTMENT_COMPONENTS_CSV : '',
  webBookingLink: process.env.VUE_APP_WEB_BOOKING_LINK,
  webBookingContactLink: (process.env.VUE_APP_WEB_BOOKING_CONTACT_LINK && process.env.VUE_APP_WEB_BOOKING_CONTACT_LINK.length) ? process.env.VUE_APP_WEB_BOOKING_CONTACT_LINK : process.env.VUE_APP_WEB_BOOKING_LINK,
  faqLink: process.env.VUE_APP_FAQ_LINK,
  practitionerMenuItems: process.env.VUE_APP_PRACTITIONER_MENU_ITEMS_CSV ? process.env.VUE_APP_PRACTITIONER_MENU_ITEMS_CSV.split(',') : [],
  practitionerExaminationImagesEnabled: process.env.VUE_APP_PRACTITIONER_EXAMINATION_IMAGES_ENABLED === 'true',
  pacsViewerCode: process.env.VUE_APP_PACS_VIEWER_CODE,
  languageSwitchEnabled: process.env.VUE_APP_LANGUAGE_SWITCH_ENABLED === 'true',
  procedureSharingEnabled: process.env.VUE_APP_PROCEDURE_SHARING_ENABLED === 'true',
  patientExaminationImagesEnabled: process.env.VUE_APP_PATIENT_EXAMINATION_IMAGES_ENABLED === 'true',
  standaloneWebBookingUrl: process.env.VUE_APP_STANDALONE_WEB_BOOKING_URL,
  standaloneWebBookingScanId: process.env.VUE_APP_STANDALONE_WEB_BOOKING_SCAN_ID,
  standaloneWebBookingRegionId: process.env.VUE_APP_STANDALONE_WEB_BOOKING_REGION_ID,
  webBookingOrgHash: process.env.VUE_APP_WEB_BOOKING_ORG_HASH,
  webBookingNoCallMeBack: process.env.VUE_APP_WEB_BOOKING_NO_CALL_ME_BACK,
  webBookingSkipClinicSelection: process.env.VUE_APP_WEB_BOOKING_SKIP_CLINIC_SELECTION,
  webBookingScheduleOnConfirm: process.env.VUE_APP_WEB_BOOKING_SCHEDULE_ON_CONFIRM,
  termsLink: process.env.VUE_APP_TERMS_LINK,
  shareOtpMethodSms: process.env.VUE_APP_SHARE_OTP_METHOD_SMS === 'true',
  shareOtpMethodGenerate: process.env.VUE_APP_SHARE_OTP_METHOD_GENERATE === 'true',
  communicationsEmail: process.env.VUE_APP_COMMUNICATIONS_EMAIL,
  cancelDialogEnabled: process.env.VUE_APP_CANCEL_DIALOG_ENABLED === 'true',
  rescheduleEnabled: process.env.VUE_APP_RESCHEDULE_ENABLED === 'true',
  cancellationPolicyLink: process.env.VUE_APP_CANCELLATION_POLICY_LINK,
  paymentEnabled: process.env.VUE_APP_PAYMENT_ENABLED === 'true',
  clientWebsiteUrl: process.env.VUE_APP_CLIENT_WEBSITE_URL || 'aptvision.com',
  safetyQuestionsAdditionalInfo: process.env.VUE_APP_SAFETY_QUESTIONS_ADDITIONAL_INFO,
  bookingAdditionalInfo: process.env.VUE_APP_BOOKING_ADDITIONAL_INFO,
  additionalDropdownsForNew: process.env.VUE_APP_PRACTITIONER_NEW_ADDITIONAL_DROPDOWN_CSV ? process.env.VUE_APP_PRACTITIONER_NEW_ADDITIONAL_DROPDOWN_CSV.split(',') : [],
  defaultColumnsGrid_appointmentPractices: process.env.VUE_APP_DEFAULT_COLUMNS_GRID_APPOINTMENT_PRACTICES ? process.env.VUE_APP_DEFAULT_COLUMNS_GRID_APPOINTMENT_PRACTICES.split(',') : [],
  callPurposeApponitmentCancelRequestId:process.env.VUE_APP_CALL_PURPOSE_APPOINTMENT_CANCEL_REQUEST_ID ? process.env.VUE_APP_CALL_PURPOSE_APPOINTMENT_CANCEL_REQUEST_ID : '',
  callSourceApponitmentCancelRequestId:process.env.VUE_APP_CALL_SOURCE_APPOINTMENT_CANCEL_REQUEST_ID ? process.env.VUE_APP_CALL_SOURCE_APPOINTMENT_CANCEL_REQUEST_ID : '',
}
